const API_ROOT = '/api';

export const PATHS = {
  ROOT: API_ROOT,
  API: {
    ROOT: API_ROOT,
    CART: `${API_ROOT}/cart`,
    ADD_ITEM: `${API_ROOT}/cart/add_item`,
    UPDATE_ITEM: `${API_ROOT}/cart/update_item`,
    CALCULATE_TAX: `${API_ROOT}/cart/calculate_tax`,
    TAX_ID_STATUS: `${API_ROOT}/cart/tax_id_status`,
    ORDER_CHECKOUT: `${API_ROOT}/order_checkout`,
    CLEAR_COUPON: `${API_ROOT}/cart/clear_coupon`,
    VALIDATE_COUPON: `${API_ROOT}/cart/validate_coupon`,
  },
};
