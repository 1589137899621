import React from 'react';
import { Frame, useSageBreakpoint, SageClassnames, Divider, Icon } from '@kajabi/sage-react';
import { localizedPriceFromMinorUnit } from 'apps/commerce/popup_checkout/util';
import { CartItemImage } from './components/CartItemImage';
import type { CartItem } from './types';

// eslint-disable-next-line no-redeclare
const CheckoutCartItem = ({ cartItem }: { cartItem: CartItem }) => {
  const { totalPrice, subtotal, coupon, discountAmount } = cartItem;
  const breakpoints = useSageBreakpoint();
  const outerFrameAlign = discountAmount
    ? Frame.ALIGNMENTS.START_SPREAD
    : Frame.ALIGNMENTS.CENTER_CENTER;

  const localizedSubtotal = localizedPriceFromMinorUnit(
    subtotal.currency,
    subtotal.amount,
    subtotal.exponent,
  );

  const localizedTotalPrice = localizedPriceFromMinorUnit(
    totalPrice.currency,
    totalPrice.amount,
    totalPrice.exponent,
  );

  const localizedDiscountAmount =
    discountAmount &&
    localizedPriceFromMinorUnit(
      discountAmount.currency,
      discountAmount.amount,
      discountAmount.exponent,
    );

  const DiscountedItemDetails = () => (
    <Frame direction={Frame.DIRECTIONS.VERTICAL} gap={Frame.GAPS.XXXS} width={Frame.WIDTHS.FILL}>
      <Frame
        direction={Frame.DIRECTIONS.HORIZONTAL}
        gap={Frame.GAPS.MED}
        align={Frame.ALIGNMENTS.START_SPREAD}
        width={Frame.WIDTHS.FILL}
      >
        <h6 className={SageClassnames.TYPE.HEADING_6} style={{ marginBottom: '0.25rem' }}>
          {cartItem.title}
        </h6>
        <s className={`${SageClassnames.TYPE_COLORS.GREY_700} ${SageClassnames.TYPE.BODY}`}>
          {localizedSubtotal}
        </s>
      </Frame>
      <Frame
        direction={Frame.DIRECTIONS.HORIZONTAL}
        gap={Frame.GAPS.XS}
        align={Frame.ALIGNMENTS.START_SPREAD}
        width={Frame.WIDTHS.FILL}
      >
        <Frame
          direction={Frame.DIRECTIONS.HORIZONTAL}
          gap={Frame.GAPS.XS}
          width={Frame.WIDTHS.FILL}
        >
          <Icon
            icon={Icon.ICONS.TAG}
            size={Icon.SIZES.L}
            color={Icon.COLORS.SECONDARY}
            adjacentType={Icon.ADJACENT_TYPES.BODY}
          />
          <p className={SageClassnames.TYPE.BODY_MED}>
            {coupon?.couponCode}
            <span
              className={`${SageClassnames.TYPE_COLORS.GREY_700} ${SageClassnames.SPACERS.XS_LEFT}`}
            >
              (-{localizedDiscountAmount})
            </span>
          </p>
        </Frame>
        <p
          className={SageClassnames.TYPE.BODY_SEMI}
          data-testid="cart-item-price"
          aria-live="polite"
        >
          {localizedTotalPrice}
        </p>
      </Frame>
    </Frame>
  );

  const ItemDetails = () => (
    <Frame
      direction={Frame.DIRECTIONS.HORIZONTAL}
      gap={Frame.GAPS.MED}
      align={Frame.ALIGNMENTS.CENTER_SPREAD}
      width={Frame.WIDTHS.FILL}
    >
      <h6 className={SageClassnames.TYPE.HEADING_6}>{cartItem.title}</h6>
      <h5
        className={SageClassnames.TYPE.HEADING_6}
        data-testid="cart-item-price"
        aria-live="polite"
      >
        {localizedTotalPrice}
      </h5>
    </Frame>
  );

  return (
    <>
      <Frame
        key={cartItem.id}
        className="cart-item"
        direction={Frame.DIRECTIONS.HORIZONTAL}
        gap={Frame.GAPS.SM}
        align={outerFrameAlign}
        width={Frame.WIDTHS.FILL}
      >
        <CartItemImage cartItem={cartItem} showQuantity={cartItem.quantityEnabled} />
        {discountAmount ? <DiscountedItemDetails /> : <ItemDetails />}
      </Frame>
      {!breakpoints.sm && <Divider />}
    </>
  );
};

export default CheckoutCartItem;
