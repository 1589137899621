import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, Frame, SageClassnames, Badge } from '@kajabi/sage-react';
import { useTranslation } from 'react-i18next';
import { Cart, CartAppearance } from './types';
import { localizedPriceFromMinorUnit } from '../popup_checkout/util';

const ManageCartSummary = ({ goToCheckout, cart }: { goToCheckout: () => void; cart: Cart }) => {
  const { totalPrice, subtotal } = cart;
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const cartAppearance: CartAppearance = getValues('cartAppearance');
  const { cartButton } = cartAppearance;
  const cartButtonStyle = {
    backgroundColor: cartButton.backgroundColor,
    color: cartButton.textColor,
    borderColor: cartButton.borderColor,
    borderRadius: `${cartButton.borderRadius}px`,
  };
  const localizedTotalPrice = totalPrice
    ? localizedPriceFromMinorUnit(totalPrice.currency, totalPrice.amount, totalPrice.exponent)
    : null;
  const localizedSubtotal = subtotal
    ? localizedPriceFromMinorUnit(subtotal.currency, subtotal.amount, subtotal.exponent)
    : null;
  return (
    <Frame
      aria-label="cart summary"
      direction={Frame.DIRECTIONS.VERTICAL}
      gap={Frame.GAPS.XS}
      className="manage-cart-summary"
    >
      <Frame
        direction={Frame.DIRECTIONS.HORIZONTAL}
        gap={Frame.GAPS.XS}
        align={Frame.ALIGNMENTS.CENTER_SPREAD}
        width={Frame.WIDTHS.FILL}
        className={`cart-subtotal ${SageClassnames.TYPE.HEADING_6}`}
      >
        <span className="" id="subtotal">
          {t('form.subtotal')}
        </span>
        <span data-testid="order-subtotal" aria-labelledby="subtotal">
          {localizedSubtotal}
        </span>
      </Frame>
      <Frame
        direction={Frame.DIRECTIONS.HORIZONTAL}
        gap={Frame.GAPS.XS}
        align={Frame.ALIGNMENTS.CENTER_SPREAD}
        width={Frame.WIDTHS.FILL}
        className={SageClassnames.SPACERS.XS_BOTTOM}
      >
        <h5 id="order-total" className={`${SageClassnames.TYPE.HEADING_6}`}>
          {t('messages.cart.order_total')}
        </h5>
        <h5
          data-testid="order-total"
          className={`${SageClassnames.TYPE.HEADING_6}`}
          aria-labelledby="order-total"
        >
          <Badge
            className={`${SageClassnames.SPACERS.XS_RIGHT}`}
            value={cart.totalPrice?.currency}
          />
          {localizedTotalPrice}
        </h5>
      </Frame>
      <Button onClick={goToCheckout} fullWidth style={cartButtonStyle}>
        {/* Add translation */}
        {cartButton.text || 'Continue to checkout'}
      </Button>
    </Frame>
  );
};

export default ManageCartSummary;
