import React from 'react';
import { Icon } from '@kajabi/sage-react';
import { CartItem } from 'apps/commerce/cart/types';

type CartItemImageProps = {
  cartItem: CartItem;
  showQuantity: boolean;
};

export const CartItemImage = ({ cartItem, showQuantity }: CartItemImageProps) => {
  const { id, title, imagePath, quantity } = cartItem;

  return (
    <div className="cart-item__media">
      {imagePath ? (
        <div className="cart-item__icon">
          <img
            alt=""
            aria-hidden="true"
            className="cart-item__image"
            data-testid={`cart-item-image-${id}`}
            src={imagePath}
          />
        </div>
      ) : (
        <Icon
          icon={Icon.ICONS.IMAGE}
          color={Icon.COLORS.GREY_600}
          size={Icon.SIZES.LG}
          className="cart-item__icon"
          data-testid={`cart-item-icon-${id}`}
          label={title}
        />
      )}
      {showQuantity && <div className="cart-count">{quantity}</div>}
    </div>
  );
};
