import { Frame } from '@kajabi/sage-react';
import React from 'react';
import CheckoutCartItem from 'apps/commerce/cart/CheckoutCartItem';
import { CartItem } from 'apps/commerce/cart/types';

const CheckoutCartItems = ({ cartItems }: { cartItems: CartItem[] }) => (
  <Frame direction={Frame.DIRECTIONS.VERTICAL} gap={Frame.GAPS.SM}>
    {cartItems.map((cartItem: CartItem) => (
      <CheckoutCartItem key={cartItem.id} cartItem={cartItem} />
    ))}
  </Frame>
);

export default CheckoutCartItems;
