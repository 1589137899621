import { useState } from 'react';
import cartClient from 'apps/commerce/cart/utils/cartClient';
import { useFormContext } from 'react-hook-form';
import { useCart } from 'apps/commerce/cart';
import { Cart } from '../../types';

export interface CouponErrorResponse {
  response: {
    data: {
      code: string;
      data: {
        cart?: Cart;
        warningCode?: string;
      };
    };
  };
}

const useCartCoupon = () => {
  const { watch, setValue } = useFormContext();
  const couponError = watch('couponError');
  const [couponCode, setCouponCode] = useState('');
  const [submittingCoupon, setSubmittingCoupon] = useState(false);
  const { context, updateCartState, setWarningCodes } = useCart();
  const { cart } = context || {};
  const { coupon: appliedCoupon } = cart || {};
  const { couponCode: appliedCouponCode, percentOff: appliedPercentOff } = appliedCoupon || {};

  const buttonDisabled = !couponCode || submittingCoupon;

  const couponErrorCodes = [
    'coupon_minimum_amount',
    'coupon_invalid',
    'coupon_expired',
    'coupon_not_found',
  ];

  const isExpectedCouponError = (code: string) => couponErrorCodes.includes(code);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCouponCode(event.target.value);
  };

  const handleCouponError = (error: CouponErrorResponse) => {
    const { code, data } = error?.response?.data || {};
    const errorCode = isExpectedCouponError(code) ? code : 'unknown_error';
    const { cart: updatedCart, warningCode: additionalWarningCode } = data || {};

    if (errorCode === 'coupon_minimum_amount') {
      const newWarnings = additionalWarningCode ? [errorCode, additionalWarningCode] : [errorCode];
      setWarningCodes(newWarnings);
    } else {
      setValue('couponError', errorCode);
      if (additionalWarningCode) {
        setWarningCodes([additionalWarningCode]);
      }
    }

    if (updatedCart) {
      // @ts-ignore
      updateCartState({ taxes: cart?.taxes, ...updatedCart });
      setValue('updatedAt', updatedCart.updatedAt);
    }
  };

  const submitCouponCode = () => {
    if (!couponCode || submittingCoupon) return;

    setSubmittingCoupon(true);
    setValue('couponError', null);

    cartClient
      .validateCoupon(couponCode)
      .then((res) => {
        const { code, data } = res;
        updateCartState({ taxes: cart?.taxes, ...data });
        setValue('updatedAt', res.data.updatedAt);
        if (code === 'unavailable_items_removed_from_cart') {
          setWarningCodes([code]);
        } else {
          setWarningCodes([]);
        }
      })
      .catch((error) => {
        handleCouponError(error);
      })
      .finally(() => {
        setSubmittingCoupon(false);
      });
  };

  const clearCoupon = () => {
    cartClient.clearCoupon().then((res) => {
      updateCartState({ taxes: cart?.taxes, ...res.data });
      setValue('updatedAt', res.data.updatedAt);
      setCouponCode('');
    });
  };

  return {
    appliedCouponCode,
    appliedPercentOff,
    buttonDisabled,
    couponCode,
    couponError,
    submittingCoupon,
    clearCoupon,
    handleOnChange,
    isExpectedCouponError,
    submitCouponCode,
  };
};

export default useCartCoupon;
