import React from 'react';
import { Button, Frame, Grid, Input, SageClassnames, Tag } from '@kajabi/sage-react';
import { useTranslation } from 'react-i18next';
import useCartCoupon from '../hooks/useCartCoupon/useCartCoupon';

const CartCouponField = () => {
  const { t } = useTranslation();
  const {
    appliedCouponCode,
    appliedPercentOff,
    buttonDisabled,
    couponCode,
    couponError,
    submittingCoupon,
    clearCoupon,
    handleOnChange,
    submitCouponCode,
  } = useCartCoupon();

  const containerClass = appliedCouponCode
    ? SageClassnames.SPACERS.MD_TOP
    : `${SageClassnames.SPACERS.MD_TOP} ${SageClassnames.SPACERS.MD_BOTTOM}`;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      submitCouponCode();
    }
  };

  return (
    <Grid className={containerClass}>
      <Grid.Row spacer="" verticalAlignment="top">
        {appliedCouponCode ? (
          <Frame direction={Frame.DIRECTIONS.HORIZONTAL} gap={Frame.GAPS.SM}>
            <Tag
              role="button"
              data-testid="coupon-clear-button"
              value={appliedCouponCode}
              showDismiss
              dismissAttributes={{
                'onClick': () => clearCoupon(),
                'aria-label': t('coupon.remove_coupon'),
              }}
            />
            <p
              className={`${SageClassnames.TYPE_COLORS.GREY_700} ${SageClassnames.TYPE.BODY_SEMI}`}
            >
              {appliedPercentOff && t('coupon.percent_off_msg', { percentOff: appliedPercentOff })}
            </p>
          </Frame>
        ) : (
          <Frame direction={Frame.DIRECTIONS.HORIZONTAL} gap={Frame.GAPS.SM}>
            <Input
              id="coupon_code"
              placeholder={t('coupon.placeholder_text')}
              value={couponCode}
              onKeyDown={handleKeyDown}
              onChange={handleOnChange}
              disabled={submittingCoupon}
              hasError={!!couponError}
              message={couponError ? t(`coupon.${couponError}`) : null}
            />
            <Button
              className="form-submit-button"
              disabled={buttonDisabled}
              loading={submittingCoupon}
              onClick={submitCouponCode}
            >
              {t('coupon.apply_button')}
            </Button>
          </Frame>
        )}
      </Grid.Row>
    </Grid>
  );
};

export default CartCouponField;
