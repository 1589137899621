import { Frame, SageClassnames } from '@kajabi/sage-react';
import ManageCartSummary from 'apps/commerce/cart/ManageCartSummary';
import React from 'react';
import ManageCartItems from 'apps/commerce/cart/ManageCartItems';
import { Cart } from 'apps/commerce/cart/types';

const ManageCart = ({
  cart,
  removeItemFromCart,
  updateCartItem,
  goToCheckout,
}: {
  cart: Cart;
  // eslint-disable-next-line no-unused-vars
  removeItemFromCart: (cartItemId: string) => void;
  // eslint-disable-next-line no-unused-vars
  updateCartItem: (cartItemId: string, quantity: number) => void;
  goToCheckout: () => void;
}) => (
  <Frame
    direction={Frame.DIRECTIONS.HORIZONTAL}
    gap={Frame.GAPS.XL}
    align={Frame.ALIGNMENTS.TOP_LEFT}
    width={Frame.WIDTHS.FILL}
    className={`manage-cart ${SageClassnames.SPACERS.MD_TOP}`}
  >
    <ManageCartItems
      cartItems={cart.items}
      removeItemFromCart={removeItemFromCart}
      updateCartItem={updateCartItem}
    />
    <ManageCartSummary goToCheckout={goToCheckout} cart={cart} />
  </Frame>
);

export default ManageCart;
