import React, { useMemo } from 'react';
import EmailInput from 'apps/commerce/common/checkout/inputs/EmailInput';
import { Frame, SageClassnames, useSageBreakpoint } from '@kajabi/sage-react';
import { useFormContext } from 'react-hook-form';
import { useElements } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import PaymentDetails from 'apps/commerce/common/checkout/inputs/PaymentDetails';
import SavedCard from 'apps/commerce/common/checkout/inputs/SavedCard';
import SaveCardCheckbox from 'apps/commerce/common/checkout/inputs/SaveCardCheckbox';
import TaxIdInput from 'apps/commerce/popup_checkout/inputs/TaxIdInput';
import type { Cart } from './types';
import CheckoutActions from './CheckoutActions';
import NameInput from '../common/checkout/inputs/NameInput';
import AddressInput from '../common/checkout/inputs/AddressInput';
import PhoneInput from '../common/checkout/inputs/PhoneInput';
import CheckoutSettings from '../common/checkout/types/CheckoutSettings';

const CheckoutCartForm = ({ cart }: { cart: Cart }) => {
  const { member, totalPrice } = cart;
  const { getValues, setValue, watch } = useFormContext();
  const { t } = useTranslation();
  const breakpoints = useSageBreakpoint();

  const { email, site, stripe, isPaymentInProgress, checkoutSettings } = getValues();
  const {
    nameRequired,
    phoneNumberRequired,
    addressRequired,
    collectTaxId,
    cardStorageOptinPreference,
    savedCardDetails,
  } = checkoutSettings as CheckoutSettings;

  const useSavedCard = watch('useSavedCard');
  const paymentProvider = watch('paymentProvider');
  const allowCardToBeSaved =
    paymentProvider === 'card' && cardStorageOptinPreference !== 'disabled';
  const saveCardDefaultValue = cardStorageOptinPreference === 'opt_in';

  // These conditions need to match the `CheckoutStripeElements` conditions for rendering Stripe's
  // Elements component otherwise this will error.
  if (stripe) {
    // the conditionality of this won't change per render, so we can safely disable the rule
    // eslint-disable-next-line react-hooks/rules-of-hooks
    setValue('elements', useElements());
  }

  const isLoggedIn = !!member;
  const shouldDisableForm = !!isPaymentInProgress;
  const isFree = totalPrice?.amount === 0;
  const shouldDisplayPaymentDetails = useMemo(
    () => stripe && site && !isFree,
    [stripe, site, isFree],
  );

  return (
    <Frame
      aria-label="cart checkout form"
      direction={Frame.DIRECTIONS.VERTICAL}
      gap={Frame.GAPS.SM}
      tag="section"
      className="checkout-cart-form"
    >
      <Frame
        className="checkout-cart-form-content"
        direction={Frame.DIRECTIONS.VERTICAL}
        gap={Frame.GAPS.SM}
        width={Frame.WIDTHS.FILL}
      >
        <Frame direction={Frame.DIRECTIONS.VERTICAL} gap={Frame.GAPS.NONE}>
          <h5 className={`${SageClassnames.TYPE.HEADING_6}`}>{t('form.contact')}</h5>
          <EmailInput disabled={isLoggedIn} defaultValue={isLoggedIn ? member?.email : email} />
        </Frame>
        {nameRequired && <NameInput />}
        {phoneNumberRequired && <PhoneInput />}
        {addressRequired && (
          <div style={{ width: '100%' }}>
            <AddressInput />
          </div>
        )}
        {collectTaxId && <TaxIdInput disabled={shouldDisableForm} />}
        {shouldDisplayPaymentDetails && (
          <Frame
            direction={Frame.DIRECTIONS.VERTICAL}
            gap={Frame.GAPS.NONE}
            width={Frame.WIDTHS.FILL}
            maxWidth="100%"
          >
            <h5 className={`${SageClassnames.TYPE.HEADING_6}`}>Payment</h5>
            <p className={`${SageClassnames.TYPE.BODY_SMALL} ${SageClassnames.SPACERS.XS_BOTTOM}`}>
              All transactions are secured and encrypted.
            </p>
            <div style={{ width: '100%' }}>
              {useSavedCard ? (
                savedCardDetails && <SavedCard savedCardDetails={savedCardDetails} />
              ) : (
                <>
                  <PaymentDetails disabled={shouldDisableForm} />
                  {allowCardToBeSaved && (
                    <SaveCardCheckbox
                      disabled={shouldDisableForm}
                      saveCardDefaultValue={saveCardDefaultValue}
                    />
                  )}
                </>
              )}
            </div>
          </Frame>
        )}
        {!breakpoints.sm && <CheckoutActions />}
      </Frame>
    </Frame>
  );
};

export default CheckoutCartForm;
