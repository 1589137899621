import React from 'react';
import { Button, Frame, Input } from '@kajabi/sage-react';
import { useTranslation } from 'react-i18next';

interface QuantitySelectorPropTypes {
  itemQuantity: number;
  handleUpdateQuantity: (quantity: number) => void;
  quantityEnabled: boolean;
  item: any;
  isQuantityLimitReached: boolean;
}

const QuantitySelector = ({
  itemQuantity,
  handleUpdateQuantity,
  quantityEnabled,
  item,
  isQuantityLimitReached,
}: QuantitySelectorPropTypes) => {
  const { t } = useTranslation();

  const handleQuantityInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updateQuantity = parseInt(event.target.value, 10);
    handleUpdateQuantity(updateQuantity);
  };

  return quantityEnabled ? (
    <Frame
      width="auto"
      direction={Frame.DIRECTIONS.HORIZONTAL}
      gap={Frame.GAPS.NONE}
      className="quantity-selector"
      data-testid="quantity-selector"
    >
      <Button
        aria-controls={`quantity-input-${item.id}`}
        aria-label={`${t('form.quantity_decrease', { item: item.title })}`}
        color="secondary"
        disabled={!quantityEnabled}
        icon="horizontal-line"
        iconOnly={true}
        id={`decrease-quantity-${item.id}`}
        key={`decrease-quantity-${item.id}`}
        onClick={() => handleUpdateQuantity(itemQuantity - 1)}
        small
      />
      <Input
        aria-label={`${t('form.quantity_of', { item: item.title })}`}
        data-testid={`quantity-input-${item.id}`}
        disabled={!quantityEnabled}
        id={`quantity-input-${item.id}`}
        key={`quantity-input-${item.id}`}
        min={1}
        onChange={handleQuantityInputChange}
        type="number"
        value={itemQuantity}
      />
      <Button
        aria-controls={`quantity-input-${item.id}`}
        aria-label={`${t('form.quantity_increase', { item: item.title })}`}
        color="secondary"
        disabled={isQuantityLimitReached}
        icon="add"
        id={`increase-quantity-${item.id}`}
        key={`increase-quantity-${item.id}`}
        iconOnly={true}
        onClick={() => handleUpdateQuantity(itemQuantity + 1)}
        small
      />
    </Frame>
  ) : null;
};

export default QuantitySelector;
