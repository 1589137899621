import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  Button,
  SageTokens,
  Frame,
  SageClassnames,
  Divider,
  Badge,
  useSageBreakpoint,
} from '@kajabi/sage-react';
import { localizedPriceFromMinorUnit } from 'apps/commerce/popup_checkout/util';
import { useTranslation } from 'react-i18next';
import type { CartItem } from './types';
import { CartItemImage } from './components/CartItemImage';
import QuantitySelector from '../common/components/QuantitySelector/QuantitySelector';

// eslint-disable-next-line no-redeclare
const ManageCartItem = ({
  cartItem,
  removeItemFromCart,
  updateCartItem,
}: {
  cartItem: CartItem;
  // eslint-disable-next-line no-unused-vars
  removeItemFromCart: (cartItemId: string) => void;
  // eslint-disable-next-line no-unused-vars
  updateCartItem: (cartItemId: string, quantity: number) => void;
}) => {
  const [itemQuantity, setItemQuantity] = useState(cartItem.quantity);
  const [displayMessage, setDisplayMessage] = useState('');

  const qtyTimeout = useRef({} as any);
  const { t } = useTranslation();
  const breakpoints = useSageBreakpoint();

  const {
    quantityEnabled,
    totalPrice,
    quantityRemaining,
    quantityLimitDisplayEnabled,
    quantityLimitDisplayThreshold,
    quantityLimitEnabled,
  } = cartItem;

  const localizedItemPrice = localizedPriceFromMinorUnit(
    totalPrice.currency,
    totalPrice.amount,
    totalPrice.exponent,
  );

  useEffect(() => {
    if (!quantityEnabled || !itemQuantity) return;
    if (quantityLimitEnabled && !quantityRemaining) return;

    if (
      quantityLimitDisplayEnabled &&
      quantityRemaining - itemQuantity <= quantityLimitDisplayThreshold
    ) {
      setDisplayMessage(`${t('form.items_remaining', { quantity: quantityRemaining })}`);
    } else {
      setDisplayMessage('');
    }

    clearTimeout(qtyTimeout.current);
    qtyTimeout.current = setTimeout(() => {
      updateCartItem(cartItem.id, itemQuantity);
    }, 500);
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(qtyTimeout.current);
  }, [itemQuantity, quantityEnabled]);

  const handleUpdateQuantity = (updateQuantity: number) => {
    if (isQuantityLimitReached && updateQuantity > itemQuantity) {
      // setDisplayMessage(t('cart.quantityLimitReached'));
      return;
    }

    // cannot remove item from cart by setting quantity to 0
    if (updateQuantity > 0 && updateQuantity !== itemQuantity) {
      setItemQuantity(updateQuantity);
    }
  };

  // check to see if the item has a quantity limit and if the quantity limit has been reached
  const isQuantityLimitReached = quantityLimitEnabled && itemQuantity >= quantityRemaining;

  const computeItemPriceLabel = useMemo(
    () =>
      `item price for ${cartItem.title} ${
        itemQuantity > 1 ? `with a quantity of ${itemQuantity}` : ''
      }`,
    [itemQuantity],
  );

  return (
    <>
      <Frame
        align={Frame.ALIGNMENTS.TOP_LEFT}
        className="cart-item"
        direction={Frame.DIRECTIONS.HORIZONTAL}
        gap={Frame.GAPS.XL}
        key={cartItem.id}
        role="group"
        width={Frame.WIDTHS.FILL}
      >
        <Frame
          key={cartItem.id}
          className="cart-item"
          direction={Frame.DIRECTIONS.HORIZONTAL}
          gap={Frame.GAPS.SM}
          align={Frame.ALIGNMENTS.TOP_LEFT}
          width={Frame.WIDTHS.FILL}
        >
          <CartItemImage cartItem={cartItem} showQuantity={false} />
          <Frame direction={Frame.DIRECTIONS.VERTICAL} gap={Frame.GAPS.XS}>
            <Frame
              direction={Frame.DIRECTIONS.HORIZONTAL}
              gap={Frame.GAPS.XS}
              align={Frame.ALIGNMENTS.CENTER_SPREAD}
              width={Frame.WIDTHS.FILL}
            >
              <h6 className={SageClassnames.TYPE.HEADING_6}>{cartItem.title}</h6>
              <Button
                color={Button.COLORS.SECONDARY}
                onClick={() => removeItemFromCart(cartItem.id)}
                icon={SageTokens.ICONS.TRASH}
                iconOnly={true}
                raised="false"
                subtle={true}
                aria-label={`remove ${cartItem.title} from cart`}
              />
            </Frame>
            <Frame
              direction={Frame.DIRECTIONS.HORIZONTAL}
              gap={Frame.GAPS.XS}
              align={Frame.ALIGNMENTS.CENTER_SPREAD}
              width={Frame.WIDTHS.FILL}
            >
              <Frame
                direction={breakpoints.sm ? Frame.DIRECTIONS.VERTICAL : Frame.DIRECTIONS.HORIZONTAL}
                gap={Frame.GAPS.XS}
                align={Frame.ALIGNMENTS.CENTER_SPREAD}
                width="auto"
              >
                {cartItem.isFree ? (
                  <Badge value={t('form.free')} />
                ) : (
                  <QuantitySelector
                    itemQuantity={itemQuantity}
                    handleUpdateQuantity={handleUpdateQuantity}
                    quantityEnabled={quantityEnabled}
                    item={cartItem}
                    isQuantityLimitReached={isQuantityLimitReached}
                  />
                )}
                {quantityLimitDisplayEnabled && displayMessage && (
                  <Frame
                    direction={Frame.DIRECTIONS.HORIZONTAL}
                    gap={Frame.GAPS.XS}
                    align={Frame.ALIGNMENTS.CENTER_LEFT}
                    width={Frame.WIDTHS.FILL}
                  >
                    <p
                      aria-live="polite"
                      aria-label={`quantity limit message for ${cartItem.title}`}
                      className={`qty-display-message ${SageClassnames.TYPE.BODY_SMALL} ${SageClassnames.TYPE_COLORS.RED_500}`}
                      data-testid="quantity-limit-message"
                    >
                      {displayMessage}
                    </p>
                  </Frame>
                )}
              </Frame>
              <div
                aria-live="polite"
                aria-label={computeItemPriceLabel}
                className={`cart-item-price ${SageClassnames.TYPE.HEADING_6}`}
                data-testid="cart-item-price"
              >
                {localizedItemPrice}
              </div>
            </Frame>
          </Frame>
        </Frame>
      </Frame>
      <Divider />
    </>
  );
};

export default ManageCartItem;
