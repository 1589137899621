import React, { useEffect, useState, useMemo } from 'react';
import { Frame, Tooltip } from '@kajabi/sage-react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import OptInCheckbox from 'apps/commerce/common/checkout/inputs/OptInCheckbox';
import ServiceAgreement from '../common/checkout/components/ServiceAgreement';

const CheckoutActions = () => {
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const {
    watch,
    getValues,
    formState: { isValid },
  } = useFormContext();
  const { t } = useTranslation();
  const isPaymentInProgress = watch('isPaymentInProgress');
  const requirePayment: boolean | undefined = watch('requirePayment');
  const isPaymentDetailComplete: boolean | undefined = watch('isPaymentDetailComplete');
  const serviceAgreementRequired: boolean | undefined = watch('serviceAgreementRequired');
  const serviceAgreementChecked: boolean | undefined = watch('serviceAgreementChecked');
  const useSavedCard: boolean | undefined = watch('useSavedCard');

  const { checkoutSettings } = getValues();
  const showDisabledCheckoutTooltip = useMemo(
    () => requirePayment && !checkoutSettings?.paymentMethods?.length,
    [checkoutSettings],
  );

  useEffect(() => {
    setSubmitEnabled(isValid && !isPaymentInProgress);
  }, [isValid, isPaymentInProgress]);

  // Disable submit if payment is in progress, user is signed in, payment details are incomplete,
  // service agreement is required but not checked, or checkout is disabled
  const siteUserSignedIn = window.Kajabi?.currentSiteUser?.type === 'User';

  const shouldDisableSubmit =
    !submitEnabled ||
    showDisabledCheckoutTooltip ||
    siteUserSignedIn ||
    (!(isPaymentDetailComplete || useSavedCard) && requirePayment) ||
    (serviceAgreementRequired && !serviceAgreementChecked);

  return (
    <Frame direction={Frame.DIRECTIONS.VERTICAL} gap={Frame.GAPS.SM}>
      <OptInCheckbox disabled={isPaymentInProgress} />
      <ServiceAgreement disabled={isPaymentInProgress} />
    </Frame>
  );
};

export default CheckoutActions;
