import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import { CustomerInformation, SubmitCartPayload } from 'apps/commerce/cart/types';
import CheckoutSettings from 'apps/commerce/common/checkout/types/CheckoutSettings';
import { PATHS } from '../constants';

const instance = applyCaseMiddleware(
  axios.create({
    xsrfCookieName: 'CART-CSRF-TOKEN',
    xsrfHeaderName: 'X-CSRF-Token',
    withCredentials: true,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  }),
);

export const getRequest = (url: string, params: any = undefined) => instance.get(url, { params });
export const postRequest = (url: string, data: any) => instance.post(url, data);
export const patchRequest = (url: string, data: any) => instance.patch(url, data);

export const fetchCart = async () => {
  const response = await getRequest(PATHS.API.CART);
  return response.data;
};

export const addCartItem = async (itemToken: string) => {
  const params = { quantity: 1, item: { type: 'offer', token: itemToken } };
  const response = await postRequest(PATHS.API.ADD_ITEM, params);
  return response.data;
};

export const updateCartItem = async (cartItemId: string, quantity: number) => {
  const params = { cartItemId, quantity };
  const response = await patchRequest(PATHS.API.UPDATE_ITEM, params);
  return response.data;
};

export const buildSubmitPayload = async (getValues: Function): Promise<SubmitCartPayload> => {
  const {
    address,
    checkoutSettings,
    saveCard,
    elements,
    email,
    member,
    name,
    optin,
    updatedAt,
    paymentProvider,
    phone,
    requirePayment = true,
    stripe,
    serviceAgreementChecked,
    useSavedCard,
    taxId,
  } = getValues();
  const {
    nameRequired,
    phoneNumberRequired,
    addressRequired,
    serviceAgreementRequired,
    collectTaxId,
    savedCardDetails,
    showOptIn,
  } = checkoutSettings as CheckoutSettings;

  const memberAttributes = {
    email: email || member.email,
    ...(nameRequired && { name }),
  };

  const customerInformationRequired = phoneNumberRequired || addressRequired || collectTaxId;
  const customerInformation = {
    phone_number: phone,
    address_line_1: address.addressLine1,
    address_line_2: address.addressLine2,
    address_city: address.city,
    address_zip: address.zip,
    address_country: address.country,
    ...(address.subdivisionRequired && { address_state: address.subdivision }),
    ...(taxId && { business_number: taxId }),
  } as CustomerInformation;

  const checkoutOptionsRequired = serviceAgreementRequired || showOptIn;
  const checkoutOptions = {
    ...(serviceAgreementChecked && { serviceAgreement: serviceAgreementChecked }),
    ...(showOptIn && { email_opt_in: optin }),
  };

  const basePayload = {
    originUrl: window.location.href,
    cartUpdatedAt: updatedAt,
    memberAttributes,
    ...(checkoutOptionsRequired && { checkoutOptions }),
    ...(customerInformationRequired && { customerInformation }),
  };

  // We'll eventually refactor this to support Paypal, but for now we can explicitly handle stripe
  let paymentMethod = null;

  if (!requirePayment) return basePayload;

  if (useSavedCard && savedCardDetails) {
    paymentMethod = {
      id: savedCardDetails.paymentMethodId,
      type: 'card',
    };
  } else if (stripe && paymentProvider) {
    paymentMethod = await createPaymentMethod(elements, email, stripe);
  }

  if (!paymentMethod) {
    return basePayload;
  }

  const consentToStorePaymentMethod = (saveCard && paymentProvider === 'card') || false;

  return {
    ...basePayload,
    paymentDetails: {
      consentToStorePaymentMethod,
      paymentProvider: 'kajabi_payments' as const,
      stripeDetails: {
        paymentMethodId: paymentMethod.id,
        paymentMethodType: paymentMethod.type,
      },
    },
  };
};

export const createPaymentMethod = async (
  elements: any,
  email: any,
  stripe: {
    createPaymentMethod: (arg0: {
      elements: any;
      // eslint-disable-next-line camelcase
      params: { billing_details: { email: any } };
    }) => PromiseLike<{ error: any; paymentMethod: any }> | { error: any; paymentMethod: any };
  },
) => {
  const { error, paymentMethod } = await stripe.createPaymentMethod({
    elements,
    params: {
      billing_details: { email },
    },
  });

  if (error) {
    // Set some error state
    return {};
  }

  return paymentMethod;
};

export const submitCart = async (getValues: Function) => {
  const params: SubmitCartPayload = await buildSubmitPayload(getValues);
  const response = await postRequest(PATHS.API.ORDER_CHECKOUT, params);
  return response.data;
};

export const fetchOrderCheckoutStatus = async (id: string) => {
  const response = await getRequest(`${PATHS.API.ORDER_CHECKOUT}/${id}/status`);
  return response.data;
};

export const fetchCheckoutSettings = async () => {
  const response = await getRequest(`${PATHS.API.CART}/checkout_settings`);
  return response.data;
};

export const calculateTax = async (addressCountry: string, addressZip: string, taxId: string) => {
  const params = {
    address_country: addressCountry,
    address_zip: addressZip,
    tax_id: taxId,
  };
  const response = await postRequest(`${PATHS.API.CALCULATE_TAX}`, params);
  return response.data;
};

export const fetchTaxIdStatus = async (country: string, taxId: string | undefined) => {
  const params = { country, taxId };
  const response = await getRequest(`${PATHS.API.TAX_ID_STATUS}`, params);
  return response.data;
};

export const clearCoupon = async () => {
  const params = {};
  const response = await postRequest(`${PATHS.API.CLEAR_COUPON}`, params);
  return response.data;
};

export const validateCoupon = async (couponCode: string) => {
  const params = { couponCode };
  const response = await postRequest(`${PATHS.API.VALIDATE_COUPON}`, params);
  return response.data;
};

const cartClient = {
  getRequest,
  postRequest,
  fetchCart,
  addCartItem,
  updateCartItem,
  submitCart,
  fetchOrderCheckoutStatus,
  fetchCheckoutSettings,
  calculateTax,
  fetchTaxIdStatus,
  clearCoupon,
  validateCoupon,
};

export default cartClient;
