import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Divider,
  Frame,
  Loader,
  SageClassnames,
  useSageBreakpoint,
} from '@kajabi/sage-react';
import CheckoutSettings from 'apps/commerce/common/checkout/types/CheckoutSettings';
import { useFormContext } from 'react-hook-form';
import CheckoutActions from './CheckoutActions';
import { Cart } from './types';
import CheckoutCartItems from './CheckoutCartItems';
import CartCouponField from './components/CartCouponField';
import { localizedPriceFromMinorUnit } from '../popup_checkout/util';
import { useCart } from './CartContext';
import PayButton from './PayButton';
import CartMessage from './CartMessage';

interface CheckoutCartSummaryProps {
  cart: Cart;
  goBackToCart: () => void;
}

const CheckoutCartSummary = ({ cart }: CheckoutCartSummaryProps) => {
  const { t } = useTranslation();
  const { getValues, watch } = useFormContext();
  const { cartCount, warningCodes } = useCart();
  const breakpoints = useSageBreakpoint();

  const { checkoutSettings } = getValues();
  const { taxInclusive } = checkoutSettings as CheckoutSettings;
  const { subtotal, totalPrice, taxes, discountAmount, showCouponField } = cart;

  const localizedSubtotal = subtotal
    ? localizedPriceFromMinorUnit(subtotal.currency, subtotal.amount, subtotal.exponent)
    : null;

  const localizedTotalPrice = totalPrice
    ? localizedPriceFromMinorUnit(totalPrice.currency, totalPrice.amount, totalPrice.exponent)
    : null;

  const localizedDiscountAmount = discountAmount
    ? localizedPriceFromMinorUnit(
        discountAmount.currency,
        discountAmount.amount,
        discountAmount.exponent,
      )
    : null;

  const isCalculatingTax = watch('isCalculatingTax');
  const hasTaxes = taxes?.totalTaxAmount && taxes.totalTaxAmount.amount > 0;
  const localizedTotalTaxes = taxes?.totalTaxAmount
    ? localizedPriceFromMinorUnit(
        taxes.totalTaxAmount.currency,
        taxes.totalTaxAmount.amount,
        taxes.totalTaxAmount.exponent,
      )
    : null;

  const pricingBreakdownClass =
    discountAmount || (hasTaxes && !taxInclusive) ? SageClassnames.SPACERS.MD_BOTTOM : '';

  const showWarningMessages = !!warningCodes?.length;

  const anyTaxExclusiveOffers = !cart.items.some((item) => item.isTaxInclusive);
  const anyTaxableOffers = cart.items.some((item) => item.isTaxable);

  return (
    <>
      {breakpoints.sm && <Divider />}
      <Frame
        aria-label="cart checkout summary"
        direction={Frame.DIRECTIONS.VERTICAL}
        gap={Frame.GAPS.XS}
        className="checkout-cart-summary"
        align={Frame.ALIGNMENTS.CENTER_SPREAD}
        tag="section"
        aria-labelledby="order-summary-heading"
      >
        <div className="checkout-cart-summary-content">
          {breakpoints.sm && (
            <h5 id="order-summary-heading" className={`${SageClassnames.TYPE.HEADING_6}`}>
              {t('messages.cart.order_summary')} ({cartCount})
            </h5>
          )}
          <CheckoutCartItems cartItems={cart.items} />
          {showCouponField && <CartCouponField />}
          <Frame
            direction={Frame.DIRECTIONS.VERTICAL}
            gap={Frame.GAPS.XS}
            className={pricingBreakdownClass}
          >
            <Frame
              direction={Frame.DIRECTIONS.HORIZONTAL}
              gap={Frame.GAPS.XS}
              align={Frame.ALIGNMENTS.CENTER_SPREAD}
              width={Frame.WIDTHS.FILL}
              className={`${SageClassnames.SPACERS.XS_TOP} ${SageClassnames.TYPE.HEADING_6}`}
            >
              <span className="cart-subtotal" id="cart-subtotal">
                {t('form.subtotal')}
              </span>
              <span>
                {' '}
                <span
                  className="cart-subtotal"
                  aria-live="polite"
                  aria-labelledby="cart-subtotal"
                  data-testid="order-subtotal"
                >
                  {localizedSubtotal}
                </span>
              </span>
            </Frame>

            {discountAmount && (
              <Frame
                direction={Frame.DIRECTIONS.HORIZONTAL}
                gap={Frame.GAPS.XS}
                align={Frame.ALIGNMENTS.CENTER_SPREAD}
                width={Frame.WIDTHS.FILL}
                className={`${SageClassnames.TYPE.HEADING_6}`}
              >
                <span className="cart-discount" id="cart-discount">
                  {t('form.discount')}
                </span>
                <span>
                  {' '}
                  <span
                    className="cart-discount-amount"
                    aria-live="polite"
                    aria-labelledby="cart-discount"
                    data-testid="order-discount"
                  >
                    -{localizedDiscountAmount}
                  </span>
                </span>
              </Frame>
            )}
            {(hasTaxes ||
              isCalculatingTax ||
              (anyTaxableOffers && anyTaxExclusiveOffers && !taxes)) &&
              !taxInclusive && (
                <Frame
                  direction={Frame.DIRECTIONS.HORIZONTAL}
                  gap={Frame.GAPS.XS}
                  align={Frame.ALIGNMENTS.START_SPREAD}
                  width={Frame.WIDTHS.FILL}
                  className={`${SageClassnames.TYPE.HEADING_6}`}
                >
                  <span className="cart-taxes" id="cart-taxes">
                    {t('form.sales_tax')}
                  </span>
                  <span>
                    {' '}
                    <span
                      className="cart-taxes"
                      aria-live="polite"
                      aria-labelledby="cart-taxes"
                      data-testid="order-taxes"
                    >
                      {isCalculatingTax ? (
                        <Loader loading={true} label={false} />
                      ) : (
                        localizedTotalTaxes
                      )}
                      {!hasTaxes && !isCalculatingTax && t('messages.cart.to_be_calculated')}
                    </span>
                  </span>
                </Frame>
              )}
          </Frame>

          <Frame
            direction={Frame.DIRECTIONS.HORIZONTAL}
            gap={Frame.GAPS.XS}
            align={Frame.ALIGNMENTS.START_SPREAD}
            width={Frame.WIDTHS.FILL}
          >
            <Frame
              direction={Frame.DIRECTIONS.VERTICAL}
              gap={Frame.GAPS.XXS}
              align={Frame.ALIGNMENTS.CENTER_LEFT}
              width={Frame.WIDTHS.HUG}
            >
              <div id="order-total" className={`${SageClassnames.TYPE.HEADING_4} order-total`}>
                {t('messages.cart.order_total')}
              </div>

              {taxInclusive && (hasTaxes || isCalculatingTax) && (
                <div className="cart-inclusive-tax-amount">
                  {isCalculatingTax
                    ? t('form.calculating_tax')
                    : t('messages.cart.includes_tax', { tax: localizedTotalTaxes })}
                </div>
              )}
            </Frame>
            <div
              data-testid="order-total"
              className={`${SageClassnames.TYPE.HEADING_4} order-total`}
              aria-labelledby="order-total"
            >
              {(taxInclusive || !isCalculatingTax) && (
                <Badge
                  className={`${SageClassnames.SPACERS.XS_RIGHT}`}
                  value={cart.totalPrice?.currency}
                />
              )}
              {taxInclusive && localizedTotalPrice}
              {!taxInclusive &&
                (isCalculatingTax ? <Loader loading={true} label={false} /> : localizedTotalPrice)}
            </div>
          </Frame>
          {breakpoints.sm && <CheckoutActions />}
          <PayButton />
          {showWarningMessages && (
            <Frame gap={Frame.GAPS.SM} className={SageClassnames.SPACERS.LG_TOP}>
              {warningCodes.map((warningCode) => (
                <CartMessage code={warningCode} type="warning" />
              ))}
            </Frame>
          )}
        </div>
      </Frame>
    </>
  );
};

export default CheckoutCartSummary;
