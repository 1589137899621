import React, { useCallback, useEffect, useState } from 'react';
import {
  Frame,
  Loader,
  Divider,
  useSageBreakpoint,
  SageClassnames,
  Button,
  Icon,
  Badge,
} from '@kajabi/sage-react';

import CheckoutStripeElements from 'apps/commerce/common/checkout/components/CheckoutStripeElements';
import CheckoutCartSummary from 'apps/commerce/cart/CheckoutCartSummary';
import CheckoutCartForm from 'apps/commerce/cart/CheckoutCartForm';
import { Cart } from 'apps/commerce/cart/types';
import * as Sentry from '@sentry/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cartClient from 'apps/commerce/cart/utils/cartClient';
import CheckoutSettings from 'apps/commerce/common/checkout/types/CheckoutSettings';
import { localizedPriceFromMinorUnit } from '../popup_checkout/util';
import CheckoutCartItems from './CheckoutCartItems';
import useToast from '../common/checkout/hooks/useToast';
import { useCart } from './CartContext';

const CheckoutCart = ({
  cart,
  goBackToCart,
  submitCheckout,
  hideModal,
  isPreview = false,
}: {
  cart: Cart;
  goBackToCart: () => void;
  submitCheckout: () => void;
  hideModal: () => void;
  isPreview?: boolean;
}) => {
  const breakpoints = useSageBreakpoint();
  const { t } = useTranslation();
  // @ts-ignore -- todo: add type to useToast
  const { showToast } = useToast();
  const { fetchCart } = useCart();

  const { totalPrice } = cart;

  const localizedTotalPrice = totalPrice
    ? localizedPriceFromMinorUnit(totalPrice.currency, totalPrice.amount, totalPrice.exponent)
    : null;

  const setCheckoutSettings = useCallback((checkoutSettings: CheckoutSettings) => {
    setValue('checkoutSettings', checkoutSettings);
    setValue('paymentMethods', checkoutSettings.paymentMethods);
    setValue('additionalPaymentMethods', checkoutSettings.additionalPaymentMethods);
    setValue('nameRequired', checkoutSettings.nameRequired);
    setValue('phoneRequired', checkoutSettings.phoneNumberRequired);
    setValue('addressRequired', checkoutSettings.addressRequired);
    setValue('serviceAgreementRequired', checkoutSettings.serviceAgreementRequired);
    setValue('serviceAgreementCustomText', checkoutSettings.serviceAgreementCustomText);
    setValue('useSavedCard', !!checkoutSettings.savedCardDetails);
  }, []);

  const handleFetchError = useCallback((err: Error) => {
    Sentry.captureException(err);
    hideModal();
    showToast({
      title: t('messages.cart.checkout_settings_error'),
      type: 'danger',
      icon: Icon.ICONS.INFO_CIRCLE,
    });
  }, []);

  // Fetch the checkoutSettings for cart-specific payment & checkout configuration
  // Stores the checkoutSettings in the form context for use in the CheckoutStripeElements component, and
  // explicitly set the paymentMethods, site and additionalPaymentMethods in the form context for use in the
  // PaymentDetails component (which depends on that data existing in the for)
  const loadCheckoutSettings = useCallback(() => {
    cartClient
      .fetchCheckoutSettings()
      .then((res) => {
        const checkoutSettings: CheckoutSettings = res.data;
        if (!checkoutSettings) {
          handleFetchError(new Error('No checkout settings found'));
          return;
        }
        setCheckoutSettings(checkoutSettings);
      })
      .catch((err) => {
        handleFetchError(err);
      });
  }, []);

  useEffect(() => {
    if (isPreview) return;
    fetchCart();
    loadCheckoutSettings();
  }, []);

  const { setValue, getValues, watch } = useFormContext();
  const { stripe } = getValues();
  const checkoutSettings: CheckoutSettings = watch('checkoutSettings');

  const [showSummary, setShowSummary] = useState(false);

  return !checkoutSettings ? (
    <Loader className="cart-loader" loading={true} />
  ) : (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <CheckoutStripeElements
      stripe={stripe}
      attemptReloadWithCardsOnly={false}
      amountPriceInCents={totalPrice?.amount || 0}
      currency={totalPrice?.currency}
      paymentMethods={checkoutSettings.paymentMethods}
      additionalPaymentMethods={checkoutSettings.additionalPaymentMethods}
      vendorAccountId={checkoutSettings.vendorAccountId}
    >
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <form
        onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
        onSubmit={submitCheckout}
        className="cart-checkout-form"
        data-testid="cart-checkout-form"
      >
        <Frame
          className="checkout-cart"
          direction={Frame.DIRECTIONS.HORIZONTAL}
          gap={Frame.GAPS.XL}
          width={Frame.WIDTHS.FILL}
        >
          {breakpoints.sm && (
            <Frame
              direction={Frame.DIRECTIONS.HORIZONTAL}
              gap={Frame.GAPS.SM}
              align={Frame.ALIGNMENTS.CENTER_SPREAD}
              className="checkout-cart-accordion"
            >
              <Button
                small
                subtle
                icon={showSummary ? 'caret-up' : 'caret-down'}
                onClick={() => setShowSummary(!showSummary)}
                className={`${SageClassnames.TYPE.BODY_SMALL} ${SageClassnames.TYPE.BODY_SEMI}`}
              >
                {showSummary ? 'Hide' : 'Show'} order summary
              </Button>
              {!showSummary && (
                <span
                  data-testid="order-total"
                  className={`${SageClassnames.TYPE.HEADING_6} price-detail`}
                  aria-labelledby="order-total"
                >
                  <Badge
                    className={`${SageClassnames.SPACERS.XS_RIGHT}`}
                    value={cart.totalPrice?.currency}
                  />
                  {localizedTotalPrice}
                </span>
              )}
            </Frame>
          )}
          {breakpoints.sm && !showSummary && <Divider />}
          {breakpoints.sm && showSummary && (
            <div className="checkout-cart-accordion-content">
              {breakpoints.sm && <Divider />}
              <div className="checkout-cart-accordion-items">
                <CheckoutCartItems cartItems={cart.items} />
                <Frame
                  direction={Frame.DIRECTIONS.HORIZONTAL}
                  gap={Frame.GAPS.XS}
                  align={Frame.ALIGNMENTS.CENTER_SPREAD}
                  width={Frame.WIDTHS.FILL}
                  className={SageClassnames.SPACERS.SM_TOP}
                >
                  <h5
                    id="mobile-order-total"
                    className={`order-total ${SageClassnames.TYPE.HEADING_5}`}
                  >
                    {t('messages.cart.order_total')}
                  </h5>
                  <h5
                    data-testid="order-total"
                    className={`${SageClassnames.TYPE.HEADING_6}`}
                    aria-labelledby="order-total"
                  >
                    <Badge
                      className={`${SageClassnames.SPACERS.XS_RIGHT}`}
                      value={cart.totalPrice?.currency}
                    />
                    {localizedTotalPrice}
                  </h5>
                </Frame>
              </div>
            </div>
          )}
          <CheckoutCartForm cart={cart} />
          <CheckoutCartSummary cart={cart} goBackToCart={goBackToCart} />
        </Frame>
      </form>
    </CheckoutStripeElements>
  );
};

export default CheckoutCart;
