import { Frame } from '@kajabi/sage-react';
import React from 'react';
import ManageCartItem from 'apps/commerce/cart/ManageCartItem';
import { CartItem } from 'apps/commerce/cart/types';

const ManageCartItems = ({
  cartItems,
  removeItemFromCart,
  updateCartItem,
}: {
  cartItems: CartItem[];
  // eslint-disable-next-line no-unused-vars
  removeItemFromCart: (cartItemId: string) => void;
  // eslint-disable-next-line no-unused-vars
  updateCartItem: (cartItemId: string, quantity: number) => void;
}) => (
  <Frame direction={Frame.DIRECTIONS.VERTICAL} gap={Frame.GAPS.MD}>
    {cartItems.map((cartItem: CartItem) => (
      <ManageCartItem
        key={cartItem.id}
        cartItem={cartItem}
        removeItemFromCart={removeItemFromCart}
        updateCartItem={updateCartItem}
      />
    ))}
  </Frame>
);

export default ManageCartItems;
